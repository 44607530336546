<template>
  <Resume />
</template>

<script>
export default {
  components: {
    Resume: () => import("@/components/Resume"),
  },
};
</script>
